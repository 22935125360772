<template>
    <BreadCrumbs/>
    <div class="row content-header">
        <h1>{{ dataInvoice.number }}</h1>
        <div class="search">
            <a @click="() => {this.$router.replace('/stock/invoice-purchase')}" class="back"><i class="fa fa-arrow-left"></i>Назад</a>
            <button class="" @click="edit()"><i class="fa fa-pencil"></i>Редагувати</button>
        </div>
    </div>

    <div class="shipper-wrap">
        <div class="shipper-col">
            <span>Поставщик:</span>
            <span>&nbsp;</span>
        </div>
        <div class="shipper-col">
            <span>{{ dataInvoice.shipper?.name }}</span>
            <span class="mail">{{ dataInvoice.shipper?.email }}</span>
        </div>
        <div class="shipper-col"><a class="icon" href="#"></a></div>
    </div>

    <div style="padding: 0 40px;"  v-if="!loading">
        <table>
            <thead>
                <tr>
                    <td style="width:3%;"><input type="checkbox"></td>
                    <td>№ <i id="sort" style="padding-left:10px;" class="fa fa-sort-numeric-asc"></i></td>
                    <td>Товар</td>
                    <td>К-ть</td>
                    <td>Од.вим</td>
                    <td>Ціна з ПДВ</td>
                    <td>Сума з ПДВ</td>
                    <td>Вихідна ціна</td>
                    <td>Ціна складу</td>
                    <td>В наявності</td>
                    <!-- <td>Х</td> -->
                </tr>
            </thead>
            <tbody>                
                <tr v-for="data in formDataList" :key="data.id">
                    <td><input type="checkbox"></td>
                    <td>{{ data.id }}</td>
                    <td>{{ data.name }}</td>
                    <td>{{ data.qtt }}</td>
                    <td>{{ data.item }}</td>
                    <td>{{ data.price }}</td>
                    <td>{{ data.sum }}</td>
                    
                    <td v-if="Math.random() > 0.5">
                        <div class="bage-wrap">
                            <b>{{ data.out_price }}</b>
                             <div class="red-bage"> <!--<div class="green-bage"> -->
                                <i>5,26%</i>                                
                            </div>
                        </div>
                    </td>
                    <td v-else>
                        <div class="bage-wrap">
                            <b>{{ data.out_price }}</b>
                             <div class="green-bage">
                                <i>5,26%</i>
                            </div>
                        </div>
                    </td>

                    <td>{{ data.out_stock }}</td>
                    <td>{{ data.in_stock }}</td>
                    <!-- <td @click="removeData(data.id)">Х</td> -->
                </tr>                
            </tbody>            
        </table>
        <div class="footer-sum">
            <div>
                <b>Всього з ПДВ:</b>                    
                <b>У тому числі ПДВ:</b>
            </div>
            <div>
                <b>123,00 грн</b>                    
                <b>123,00 грн</b>
            </div>
        </div>
    </div>

    <!-- loading -->
    <div v-if="loading" class="lds-dual-ring"></div>
</template>

<style>
.back{
    color: #667085;
    margin-right: 20px;
    cursor: pointer;
}
.back i{
    margin-right: 15px;
}
.shipper-wrap{
    display: flex;    
    align-items: center;
    padding: 15px 50px;    
}
.shipper-wrap 
.shipper-col{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 30px;    
}
.shipper-wrap 
.shipper-col .mail{

}

.shipper-wrap 
.shipper-col .icon{
    background-image: url('../../../../assets/icon/link-03.svg');
    width: 24px;
    height: 24px;
}

.bage-wrap{
    display: flex;
    align-items: center;
}

.bage-wrap .green-bage{
    background-color: #ECFDF3;
    color: #027A48;
    border-radius: 10px;
    margin-left: 10px;
    padding: 5px 15px;
}

.bage-wrap .green-bage i{
    background-image: url('../../../../assets/icon/arrow-top.svg');
    display: block;
    background-repeat: no-repeat;
    padding-left: 15px;
    background-position-y: 2px;
}

.bage-wrap .red-bage{
    background-color: #FEF3F2;
    color: #B42318;
    border-radius: 10px;
    margin-left: 10px;
    padding: 5px 15px;
}

.bage-wrap .red-bage i{
    background-image: url('../../../../assets/icon/arrow-bottom.svg');
    display: block;
    background-repeat: no-repeat;
    padding-left: 15px;
    background-position-y: 3px;
}

.footer-sum{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 20px 0;
}

.footer-sum div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-sum div b{
    padding: 3px;
}

</style>

<script> 
    import { mapActions } from 'vuex';   
    
    import BreadCrumbs from '@/components/Breadcrumbs.vue'
    export default{
        name: 'InvoiceItems',
        components: {
            BreadCrumbs
        },
        data() {
            //this.$route.meta.h1 = 'Прибуткова накладна - ' + this.$route.params.invoice_id;

            return {
                formDataList: [
                    /*{
                        id: Date.now(),
                        name: "" + this.$route.meta.h1,
                        count: Math.floor(Math.random() * 100),
                        single: true,
                        prise_pdv: Math.floor(Math.random() * 100),
                        sum_pdv: Math.floor(Math.random() * 100),
                        oute_price: Math.floor(Math.random() * 100),
                        oute_stock: Math.floor(Math.random() * 100),
                        in_stok: Math.floor(Math.random() * 100),
                    },*/
                ],
                dataInvoice: [],
                loading: false,
            };
        },
        methods: {
            ...mapActions(['setDataArray']),

            removeData(id) {
                this.formDataList = this.formDataList.filter(data => data.id !== id);
            },
            edit(){
                this.setDataArray(this.formDataList);

                this.$router.replace({ path: "/stock/invoice-purchase/" + this.$route.params.invoice_id + "/edit" });
            },
            fetchData() {
                this.loading = true;

                //invoice
                this.$axios.get('/invoices/' + this.$route.params.invoice_id)
                .then(response => {                
                    this.dataInvoice = response.data;
                })
                .finally(() => {
                    this.loading = false;
                });

                this.$axios.get('/invoices-items?filter[invoices_id]=' + this.$route.params.invoice_id)
                .then(response => {
                    this.formDataList = response.data.items;
                    //console.log(this.formDataList);                    
                })
                .catch(error => {
                    this.error = error.message;
                })
                .finally(() => {
                    this.loading = false;
                });
            },
        },
        mounted(){
            this.fetchData();
        }
    }
</script>