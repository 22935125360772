<template>
    <BreadCrumbs/>
    <div class="row content-header">
        <h1>Додати</h1>
        <div class="search">
            <button class="purpur" style="padding:12px;margin:0px;width:auto;height:auto;" @click="openPopup"><i class="fa fa-gear" style="padding:0;"></i></button>
            <button @click="() => {this.$router.replace('/dictionary/products')}">Скасувати</button>
            <button class="purpur" @click="save">Зберегти</button>
        </div>
    </div>
    <div class="content">
        <div class="row" style="padding:0 50px;">
            <div class="col col-1" style="width: 65%;">
                <div class="item">
                    <span>Назва товару</span>
                    <input type="text" v-model="productName">
                </div>
                <div class="item">
                    <span>Категорія</span>
                    <select v-model="categorySelected">
                        <option v-for="category in categories" :value="category.id" :key="category.id">
                            {{ category.name }}                            
                        </option>                        
                    </select>                    
                    <!-- <div class="check-select">
                        <div class="section-container" v-for="category in categories" :value="category.id" :key="category.id">
                            <div class="select-section">
                                <label :for="'checkbox_' + category.id">{{ category.name }}</label>
                                <input type="checkbox" :val="category.id" :id="'checkbox_' + category.id"/>
                            </div>
                        </div>
                    </div> -->
                </div>

                <div class="row any" style="width:100%">
                    <div class="item" v-for="(field, index) in anyfields" :key="index">
                        <span>{{ field.name }}</span>
                        <input :type="field.type" v-model="anyfields[index].value" >                        
                    </div>
                </div>

                <div class="row" style="width:100%">
                    <div class="item">
                        <span>Опис (опціонально)</span>
                        <textarea></textarea>
                    </div>
                </div>
            </div>

            <div class="col" style="width: 35%;">
                <div class="item">
                    <div class="img-wrap">
                        <div 
                            class="img"
                            v-bind:style="[isDragging ? 'border:3px dotted #000' : '']"
                            @dragover.prevent
                            @dragenter="dragEnter"
                            @dragleave="dragLeave"
                            @drop="handleDrop"
                            @click="openFileDialog"
                        >
                            <img v-if="!imageUrl" src="@/assets/icon/photo.png">
                            <img class="loaded-images" v-if="imageUrl" :src="imageUrl" alt="Uploaded Image" />
                            <input type="file" ref="fileInput" style="display: none" @change="handleFileInput" />
                        </div>
                    </div>
                </div>
            </div>
                        
        </div>
    </div>

    <popupAnyfields
        v-if="showPopup"
        @closePopup="closePopup"
        @popupData="handlePopupData"
        :popupData="anyfields"
    />

</template>

<style scoped>
    .check-select{
        display: flex;
        justify-content: flex-start;
        width: 98%;
        border: 1px solid #D0D5DD;
        /* height: 45px; */
        border-radius: 10px;
        padding-left: 15px;
        font-size: 19px;
        background-color: #fff;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px 10px;
    }
    .select-section{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;        
        border: 1px solid #D0D5DD;
        height: 30px;
        border-radius: 5px;
        margin-right: 5px;
        padding: 2px;
        margin-bottom: 10px;
    }
    .section-container{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        border-bottom: 1px dotted #D0D5DD;
        margin-bottom: 5px;
        margin-top: 5px;
        width: 100%;
    }
    .check-select input{
        width: 18px!important;;
    }
    .row{
        display: flex;
        flex-direction: row; 
        flex-wrap: wrap;       
    }
    .item{
        margin-top: 10px;        
        width: auto!important;
    }
    .any .item{
        width: 50%!important;
        
    }
    .col-1{
        width: 65%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: stretch;
        align-content: flex-start;
    }
    .item input{
        width: calc(100% - 20px);
    }
    .item select{
        width: 100%;
        height: 45px;
    }
    .item textarea{
        border: 1px solid #D0D5DD;        
        border-radius: 10px;
        padding-left: 15px;
        font-size: 19px;
        width: calc(100% - 20px);
        min-height: 100px;
    }
    .img-wrap{                
        width: 97%;
        padding: 28px;
        cursor: pointer;
    }
    .img-wrap .img{
        border: 1px solid #D0D5DD;
        border-radius: 10px;
        background-color: #F9F5FF;
    }
    .loaded-images{
        max-width: 70%;
        margin: 40px;
    }
</style>

<script>
    import BreadCrumbs from '@/components/Breadcrumbs.vue';
    import { sortedCategories } from '@/functions';

    import popupAnyfields from '@/components/popupAnyfields.vue';

    export default {
        name: 'DictionaryProductsAdd',
        components: {            
            BreadCrumbs,
            popupAnyfields
        },
        created() {
            this.loading = true;
            
            this.$axios.get('/categories/tree')
                .then(response => {
                    this.categories = sortedCategories(response.data);                    
                    this.loading = false;
                })
                .catch(error => {
                    console.error(error);
                });

            this.$axios.get('/anyfields?filter[product_id]=0')
                .then(response => {
                    this.anyfields = response.data.items;
                    this.loading = false;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        data(){
            this.$route.meta.h1 = "";
            this.$route.meta.h1 = 'Додати новий товар';
            return {
                imageUrl: false,
                isDragging: false,
                categories: [],
                categorySelected: 1,
                showPopup: false,
                anyfields: [],                
                productName: null,
                formData: []
            }
        },
        methods:{
            openFileDialog() {
                this.$refs.fileInput.click();
            },
            dragEnter(event) {
                event.preventDefault();
                this.isDragging = true;                
            },
            dragLeave() {
                this.isDragging = false;                
            },
            handleDrop(event) {
                event.preventDefault();
                this.isDragging = false;
                this.uploadFile(event.dataTransfer.files[0]);
            },
            handleFileInput() {
                const file = this.$refs.fileInput.files[0];
                this.uploadFile(file);
            },
            async uploadFile(file) {
                const formDataImg = new FormData();
                formDataImg.append('image', file);

                try {
                    const response = await this.$axios.post('/products/upload', formDataImg, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });

                    this.imageUrl = response.data.imgurl;
                } catch (error) {
                    console.error(error);                    
                }
            },
            openPopup(){
                //this.popupData = { ...data };
                this.showPopup = true;
            },
            handlePopupData(data) {
                this.anyfields = [];
                data.forEach(el => {
                    this.anyfields.push(el);
                });
            },
            closePopup() {
                this.showPopup = false;
                //this.popupData = [];
            },
            save(){
                this.formData = {
                    name: this.productName,
                    category_id: this.categorySelected,
                    imgurl: this.imageUrl,
                    anyfields: this.anyfields,
                };
                this.$axios.post('/products', this.formData)
                .then(response => {                    
                    console.log(response);
                })
                .catch(error => {
                    console.error(error);
                });

                //console.log(this.anyfields);
            }          
        }
    }
</script>