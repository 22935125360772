import Invoices from '../Invoices.vue';
import InvoiceContainer from '../InvoiceContainer.vue';
import StockInvoiceItems from '../purchase/InvoiceItems.vue';
import StockInvoiceEdit from '../purchase/edit.vue';
import StockInvoiceAdd from '../purchase/add.vue';

const invoice_purchase = {
    path: '/stock/invoice-purchase',
    name: 'invoice-purchase',
    meta:{
        h1: 'Прибуткові накладні',
    },
    children:[
      {
        path: '/stock/invoice-purchase',
        name: 'invoice-purchase',
        component: Invoices
      },
      {
        path: ':invoice_id',
        name: 'purchase_invoice_id',
        meta:{
            h1: 'Прибуткова накладна',
        },
        component: StockInvoiceItems
      },
      {
        path: ':invoice_id/edit',
        name: 'purchase_edit',        
        component: StockInvoiceEdit,        
      },
      {
        path: 'add',
        name: 'purchase_add',
        component: StockInvoiceAdd
      }
    ],
    component: InvoiceContainer,
}

export default invoice_purchase