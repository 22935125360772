import Goods from './Goods.vue';
import GoodsAdd from './add.vue';
import GoodsContainer from './GoodsContainer.vue';

const deliveryOrder = {
    path: '/stock/delivery-order',
    name: 'deliveryOrder',
    meta:{
        h1: 'Замовлення на поставку',
    },
    children:[
      {
        path: '/stock/delivery-order',
        name: 'deliveryOrder',
        component: Goods
      },
      {
        path: 'add',
        name: 'deliveryOrder_add',
        component: GoodsAdd
      }
    ],
    component: GoodsContainer,
}

export default deliveryOrder