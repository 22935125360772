<template>
    <form class="table-wrapper">
        <table>
            <thead>
                <tr>
                    <td style="width:3%;"><input type="checkbox" @click="selectAll" v-model="allSelected"></td>
                    <td style="width:8%;">Код&nbsp;&nbsp;&nbsp;<i class="fa fa-sort-alpha-asc"></i></td>                    
                    <td style="width:8%;">Фото</td>
                    <td>Назва товару&nbsp;&nbsp;&nbsp;<i class="fa fa-sort-alpha-asc"></i></td>
                    <td class="tbl-buttons"></td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in TablesItems" :key="item.id">
                    <td><input type="checkbox" @change="select" v-model="itemIds" :value="item.id"></td>
                    <td @click="openPopup(item)">{{ item.number }}</td>
                    <td @click="openPopup(item)"><img :src="item.image ? item.image.path : ''"></td>
                    <td @click="openPopup(item)">{{ item.name }}</td>
                    <td class="tbl-buttons"><i class="fa fa-pencil"></i></td>
                </tr>
            </tbody>            
        </table>
    </form>

    <!-- loading -->
    <div v-if="loading" class="lds-dual-ring"></div>

    <popupProductView
        v-if="showPopup"
        @closePopup="closePopup"
        :popupData="popupData"        
    />
</template>

<style>
.table-wrapper{
    padding: 0 40px;
}

.table-wrapper a{
    color: #000;
}

thead tr td{
    color:#667085;
}

table {
    width: 100%;
    border-collapse: collapse;  
}
table img {
    max-width: 40px;
    max-height: 40px;
}
th, td {
    vertical-align: middle;
    text-align: left;
    padding: 7px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}
th {
    font-weight: bold;
}
td p {
    margin: 0;
    color:#667085;
}
tr th:first-child,
tr td:first-child {    
    text-align: left;
    max-height: 52px;    
}
td:nth-child(3) {
    color:#667085;    
}
tbody tr:nth-child(even) {
  background-color:#F9FAFB;
}
tbody tr:nth-child(odd) {
  background-color: #fff;
  border-top: 2px solid #EAECF0;
  border-bottom: 2px solid #EAECF0;
}
input[type=checkbox]{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.tbl-buttons{
    text-align: center;
    font-size: 22px;
    color: #667085;
    cursor: pointer;
}
.tbl-buttons.right{
    text-align: right;
}
.child::before{
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    content: " \f105 ";
    padding: 0 8px;
}
.child:first-child::before{
    display: none;
}
</style>

<script>
    import popupProductView from '@/components/popupProductView.vue'

    export default{ 
        name:'ProductsList',
        components:{
            popupProductView
        },
        data() {            
            return {
                loading : false,
                allSelected: false,
                showPopup : false,
                popupData : [],
                itemIds: [],
                anyfields: {},
            }
        },
        props:[
            'TablesItems',
            //TablesItems:Object,
            'checkedItems'
        ],        
        methods:{
            openPopup(data){
                this.popupData = { ...data };
                this.showPopup = true;                
            },
            closePopup() {
                this.showPopup = false;
                this.popupData = [];
            },
            selectAll() {
                this.itemIds = [];

                if (!this.allSelected) {
                    this.TablesItems.forEach(element => {
                        this.itemIds.push(element.id.toString());
                    });                    
                }
                this.$emit('checkedItems', this.itemIds);
            },
            select(){
                this.allSelected = false;                
                this.$emit('checkedItems', this.itemIds);
            },
        },
        created(){            

        },
    }
</script>