import axios from 'axios';

const TOKEN_KEY = 'token';
//const DOMAIN = 'http://backend';
//const DOMAIN = '192.168.0.101';
const DOMAIN = 'https://radio.reactlogic.com';

// Установка токена в localStorage
export function setToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
}

// Получение токена из localStorage
export function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

// Удаление токена из localStorage
export function removeToken() {
  localStorage.removeItem(TOKEN_KEY);
}

// Проверка авторизации
export function isAuthenticated() {
  return !!getToken();
}

// Аутентификация пользователя
export function login(username, password) {
    const params = {
        username: username,
        password: password
    };
    return axios.post( DOMAIN + '/v1/user/login', params)
        .then(response => {
            const token = response.data.access_token;
            setToken(token);
            return token;
        });
}

// Выход пользователя (удаление токена)
export function logout() {
  removeToken();
}