<template>
    <BreadCrumbs/>
    <div class="row content-header">
        <h1>{{ this.$route.meta.h1 }}</h1>
        <div class="search">
            <i class="fa fa-search" @click="search"></i>
            <input v-model.trim="searchText" v-on:keyup.enter="search" type="text" placeholder="Пошук">
            <button><i class="fa fa-cloud-download"></i>Імпорт</button>
            <button class="purpur" @click="add()"><i class="fa fa-plus"></i>Додати</button>
        </div>
    </div>
    
    <!-- loading -->
    <div v-if="loading" class="lds-dual-ring"></div>
    <!-- items -->
    <InvoicesList :TablesItems="this.TablesItems" v-if="!loading" />
    <!-- Pagination -->
    <PaginationItems :ItemsObject="this.pagination" @PaginationData="PaginationData"/>    
</template>

<style>
    .body{
        background-color: #F9FAFB;
    }
    .invoices button{
        border: 1px solid #D0D5DD;
        height: 44px;
        width: 120px;
        border-radius: 10px;    
        font-size: 16px;
        margin-left: 16px;
        cursor: pointer;
        background-color: #FFF;
        color: #344054;
    }
    .invoices button i{
        padding-right: 7px;
    }
    .invoices button.purpur {
        background-color: #7F56D9;
        color:#FFF;
    }
</style>

<script>
    
    import BreadCrumbs from '@/components/Breadcrumbs.vue'
    import InvoicesList from '@/components/InvoicesList.vue'
    import PaginationItems from '@/components/PaginationItems.vue'

    export default {
        name: 'InvoicesView',
        components: {            
            BreadCrumbs,             
            InvoicesList,
            PaginationItems
        },
        data(){            
            return {
                searchText: '',
                pagination:{},
                
                loading: false,
                TablesItems:[
                    /*{
                        id: '1',
                        number:'ПН-0001',
                        date:'10.10.2023',
                        shipper:{
                            name: 'ЭлектроКомпонент ФОП',
                            email: 'test@test.test',
                        },
                    }*/
                ],                
            }
        },
        methods:{
            search(){                
                const type = this.$route.name.split("-")[1];                
                const url = 'invoices?filter[type]=' + type + '&filter[number][like]=' + this.searchText;
                this.fetchData(url);
            },
            PaginationData(data) {
                const link = data.replace("http://backend/v1", "");
                this.fetchData(link);
            },
            add(){                
                this.$router.replace({ path: this.$route.href + "/add" });
            },
            async fetchData( url=false ) {                
                if( !url ){                    
                    switch(this.$route.name){
                        case 'invoice-purchase':
                        url = '/invoices?filter[type]=purchase';
                            break;
                        case 'invoice-outgoing':
                        url = '/invoices?filter[type]=outgoing';
                            break;
                    }
                }
                this.loading = true;
                await this.$axios.get(url)
                    .then(response => {
                        this.TablesItems = response.data.items;
                        this.pagination = {
                            links: response.data._links,
                            meta: response.data._meta,
                        };
                    })
                    .catch(error => {
                        this.error = error.message;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        },
        async mounted(){
            await this.fetchData();
        }
    }
</script>