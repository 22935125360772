<template>
    <BreadCrumbs/>
    <div class="row content-header">
        <h1>Сторінка додавання товару</h1>
        <div class="search">
            <button @click="() => {this.$router.replace('/stock/goods')}">Скасувати</button>
            <button class="purpur">Зберегти</button>
        </div>
    </div>
    <div class="content">        
        new item
    </div>
</template>

<style scoped>

</style>

<script>
    import BreadCrumbs from '@/components/Breadcrumbs.vue'           

    export default {
        name: 'StockGoodsAdd',
        components: {            
            BreadCrumbs,            
        },
        data(){
            this.$route.meta.h1 = "";
            this.$route.meta.h1 = 'Додати новий товар';
            return {

            }
        },
    }
</script>