import Invoices from '../Invoices.vue';
import InvoiceContainer from '../InvoiceContainer.vue';
import StockInvoiceItems from '../outgoing/InvoiceItems.vue';
import StockInvoiceEdit from '../outgoing/edit.vue';
import StockInvoiceAdd from '../outgoing/add.vue';

const invoice_outgoing = {
    path: '/stock/invoice-outgoing',
    name: 'invoice-outgoing',
    meta:{
        h1: 'Видаткові накладні',
    },
    children:[
      {
        path: '/stock/invoice-outgoing',
        name: 'invoice-outgoing',
        component: Invoices
      },
      {
        path: ':invoice_id',
        name: 'outgoing_invoice_id',
        component: StockInvoiceItems
      },
      {
        path: ':invoice_id/edit',
        name: 'outgoing_edit',
        component: StockInvoiceEdit
      },
      {
        path: 'add',
        name: 'outgoing_add',
        component: StockInvoiceAdd
      }
    ],
    component: InvoiceContainer,
}

export default invoice_outgoing