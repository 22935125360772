import HomeView from '../home/HomeView.vue'

const home =
  {
    path: '/',
    name: 'home',
    meta:{
      h1: 'Головна панель',
      description: 'Головна панель...',
    },
    component: HomeView,
  }

  export default home