<template>    
    <BreadCrumbs/>
    <div class="row content-header" style="">
        <!-- <h1>Каса</h1>
        <div class="search">
            <i class="fa fa-search"></i>
            <input type="text" name="search" placeholder="Пошук">                
        </div> -->
    </div>

    <div class="container">
        <div class="">            
            <div class="order">
                <ul class="orderlines">
                    <li class="orderline selected" v-for="i in 10" :key="i">
                        <span class="product-name">Транзистор BF245C n-канальний 1</span>
                        <span class="price">3.60&nbsp;₴</span>
                        <ul class="info-list">
                            <li class="info">1,00&nbsp;Шт. / 3.60&nbsp;₴ / Шт.</li>
                        </ul>
                    </li>
                </ul>
                <div class="summary clearfix">
                    <div class="line">
                        <div class="entry total">
                            <span class="badge">Итого: </span>
                            <span class="value">14.40&nbsp;₴</span>
                            <div class="subentry">
                                Налоги: <span class="value">0.60&nbsp;₴</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="keyboard">
                <div class="parent">
                    <div class="div1 key key_w">1 </div>
                    <div class="div2 key key_w">2 </div>
                    <div class="div3 key key_w">3 </div>
                    <div class="div4 key key_w" style="background: #F6F3FF;">к-во</div>
                    <div class="div5 key key_w">4 </div>
                    <div class="div6 key key_w">5 </div>
                    <div class="div7 key key_w">6</div>
                    <div class="div8 key key_w">%disc</div>
                    <div class="div9 key key_w">7</div>
                    <div class="div10 key key_w">8</div>
                    <div class="div11 key key_w">9</div>
                    <div class="div12 key key_w">Ціна</div>
                    <div class="div13 key key_w">+/-</div>
                    <div class="div14 key key_w">0</div>
                    <div class="div15 key key_w">,</div>
                    <div class="div16 key key_w">Back</div>
                    <div class="div17 key">Картка</div>
                    <div class="div18 key">Готівка</div>
                    <div class="div19 key" style="background:#039855;color:#D0D5DD;">Сплата</div>
                </div>
            </div>
            </div>

 
        </div>
        <div class="" style="width: 100%;">
            <div class="search" style="justify-content: flex-start;margin-left: -8px;"><i class="fa fa-search"></i><input type="text" name="search" placeholder="Пошук"></div>
            
            <br>
            
            <div class="products-block">
                <div class="category-item" v-for="i in 14" :key="i">Транзистори</div>
            </div>
            
            <div style="float: none; clear: both;border-bottom: 1px dotted #d0d5dd;margin: 5px;padding: 5px;"></div>

            <div class="products-block">
                <div class="product-item" v-for="i in 18" :key="i">
                    <img src="@/assets/img/detal1_big.jpg">
                    <span>Транзистор BF245C n-канальний</span>
                </div>
            </div>

            <div style="float: none; clear: both;"></div>
            <div class="pagination" style="display: flex;padding: 15px 10px;"><button class="disbled"><i class="fa fa-arrow-left"></i>Назад</button><div class="center-text"><b>Сторінка 1 з 2</b></div><button class="">Вперед<i class="fa fa-arrow-right"></i></button></div>

        </div>
    </div>

    <!-- loading -->
    <div v-if="loading" class="lds-dual-ring"></div>
    
</template>

<style>
    .container{
        display: flex;
        text-align: left;
        justify-content: center;
    }
    .w-50{
        width: 48.99%;
    }
    .f{
        display: flex;
        flex-direction: column;
    }
    .products-block{        
        padding-left: 5px;
        padding-top: 5px;
    }
    .product-item{
        width: 120px;
        height: 120px;
        border: 2px solid #D0D5DD;
        margin: 5px;
        float: left;
        border-radius: 5px;
        text-align: center;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }
    .product-item img{
        max-width: 70px;
    }
    .product-item span{
        font-size: 10px;
    }    
    .category-item{
        /* width: 120px;
        height: 40px; */
        border: 2px solid #D0D5DD;
        margin: 5px;
        float: left;
        border-radius: 5px;
        text-align: center;
        background-color: #fff;
        padding: 10px;
        cursor:pointer;
    }
    .order {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        flex-direction: column;
        height: 100%;
        background: white;
        font-size: 16px;
        text-align: left;
        max-width: 500px;
        -webkit-transform: translate3d(0, 0, 0);
    }
    .order .orderlines {
        flex-grow: 1;
        overflow: auto;
        max-height: 200px;
    }
    .order .orderline {
        width: 100%;
        margin: 0px;
        padding: 7px 15px;
        cursor: pointer;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -ms-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: background 250ms ease-in-out;
        -moz-transition: background 250ms ease-in-out;
        transition: background 250ms ease-in-out;
    }
    .order .orderline.selected {
        background: rgba(1, 126, 132, 0.2);
    }
    .order .orderline .product-name {
        padding: 0;
        display: inline-block;
        font-weight: bold;
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .order .orderline .price {
        padding: 0;
        font-weight: bold;
        float: right;
    }
    ul, li {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    .order .orderline .info-list {
        color: #4A4F59;
        margin-left: 10px;
    }
    .order .summary {
        position: sticky;
        bottom: 0;
        width: 100%;
        text-align: right;
        font-weight: bold;
        padding: 8px 16px;
        border-top: 1px solid #E0E2E6;
        box-sizing: border-box;
        background: inherit;
    }
    .order .summary .total {
        font-size: 22px;
    }
    .order .summary .line .subentry {
        font-size: 16px;
        font-weight: normal;
    }
    .keyboard{
        display: flex;
    }
    .keyboard .parent{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;        
    }

    .div1 { grid-area: 1 / 1 / 2 / 2; }
    .div2 { grid-area: 1 / 2 / 2 / 3; }
    .div3 { grid-area: 1 / 3 / 2 / 4; }
    .div4 { grid-area: 1 / 4 / 2 / 5; }
    .div5 { grid-area: 2 / 1 / 3 / 2; }
    .div6 { grid-area: 2 / 2 / 3 / 3; }
    .div7 { grid-area: 2 / 3 / 3 / 4; }
    .div8 { grid-area: 2 / 4 / 3 / 5; }
    .div9 { grid-area: 3 / 1 / 4 / 2; }
    .div10 { grid-area: 3 / 2 / 4 / 3; }
    .div11 { grid-area: 3 / 3 / 4 / 4; }
    .div12 { grid-area: 3 / 4 / 4 / 5; }
    .div13 { grid-area: 4 / 1 / 5 / 2; }
    .div14 { grid-area: 4 / 2 / 5 / 3; }
    .div15 { grid-area: 4 / 3 / 5 / 4; }
    .div16 { grid-area: 4 / 4 / 5 / 5; }
    .div17 { grid-area: 5 / 1 / 6 / 3; }
    .div18 { grid-area: 5 / 3 / 6 / 5; }
    .div19 { grid-area: 6 / 1 / 7 / 5; }

    .key{
        padding: 25px;
        border: 2px solid #D0D5DD;
        text-align: center;
        margin: 2px;
        border-radius: 5px;
        background-color: #fff;
        font-size: 28px;        
    }
    .key_w{
        width: 55px;
    }
</style>

<script>
    // import BreadCrumbs from '@/components/Breadcrumbs.vue'
    // import CardsItems from '@/components/Cards.vue'

    export default {
        name: 'CashView',
        // components: {            
        //     BreadCrumbs,
        //     CardsItems
        // },
        // data(){            
        //     return {
        //         loading: false,
        //         CardItems:[
        //             {id:444, path:'/stock/goods', count: 2, icon: 'fa fa-cube', caption: 'Товари на складі'}
        //             // {id:1, path:'/stock/invoice-purchase', count: 151, icon: 'fa fa-file-text-o', caption: 'Прибуткові накладні'},
        //             // {id:2, path:'/stock/invoice-outgoing', count: 100, icon: 'fa fa-file-text-o', caption: 'Видаткові накладні'},                    
        //             // {id:3, path:'/stock', count: 1243, icon: 'fa fa-edit', caption: 'Замовлення на поставку'},
        //             // {id:4, path:'/stock', count: 1223, icon: 'fa fa-cube', caption: 'Товари на складі'},
        //             // {id:5, path:'/stock', count: 156, icon: 'fa fa-history', caption: 'Повернення'},
        //             // {id:6, path:'/stock', count: 15671, icon: 'fa fa-shopping-basket', caption: 'Продажі'},
        //         ]
        //     }
        // },
        // mounted(){
        //     this.loading = true;

        //     this.$axios.get('/invoices/count').then(response => {
        //         //console.log(response);
        //         response.data.forEach(element => {
        //             switch(element.type){
        //                 case 'purchase':
        //                     //this.CardItems.push({id:element.id, path:'/stock/invoice-purchase', count: element.count, icon: 'fa fa-file-text-o', caption: 'Прибуткові накладні'});
        //                     break;
        //                 case 'outgoing':
        //                     //this.CardItems.push({id:element.id, path:'/stock/invoice-outgoing', count: element.count, icon: 'fa fa-shopping-basket', caption: 'Видаткові накладні'});
        //                     break;
        //             }
        //         });
        //     })
        //     .finally(() => {
        //         this.loading = false;
        //     });
        // }
    }
</script>