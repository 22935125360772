import Goods from './Goods.vue';
import GoodsAdd from './add.vue';
import GoodsContainer from './GoodsContainer.vue';

const goods = {
    path: '/stock/goods',
    name: 'goods',
    meta:{
        h1: 'Товари на складі',
    },
    children:[
      {
        path: '/stock/goods',
        name: 'goods',
        component: Goods
      },
      {
        path: 'add',
        name: 'goods_add',
        component: GoodsAdd
      }
    ],
    component: GoodsContainer,
}

export default goods