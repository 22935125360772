<template>
    <div class="popup-overlay">
        <div class="popup-content">
            <i @click="closePopup" class="x fa fa-times"></i>
            <h3>{{ h1 }}</h3>
            <form @submit="submitForm">
              
                  <div class="column-wrap">
                  <div class="column">

                      <div class="col">
                          <span>Товар</span>
                          <select v-model="formData.name" style="width:100%;height:45px;">
                              <option 
                                  v-for="i in 5" 
                                  :key="i"
                                  :value="'Поставщик - ' + i"                              
                              >Товар поставщика {{ i }}</option>
                          </select>
                      </div>              
                  
                      <div class="col">
                          <span>кол-во</span>
                          <input type="number" min="0" v-model="formData.qtt">
                      </div>
                  
                      <div class="col">
                          <span>Од.вим</span>
                          <select v-model="formData.item" style="height:45px;">
                              <option>шт</option>
                          </select>
                      </div>

                      <div class="col">
                          <span>Ціна з ПДВ</span>
                          <input type="number" step="0.01" min="0" v-model="formData.price">
                      </div>

                  </div>

                  <div class="column">

                      <div class="col">
                          <span>Вихідна ціна %</span>
                          <input type="number" step="0.01" min="0" v-model="formData.out_price">
                      </div>

                      <div class="col">
                          <span>Вихідна ціна % в ручну</span>
                          <input type="number" step="0.01" min="0" v-model="formData.sum">
                      </div>

                      <div class="col">
                          <span>Ціна складу</span>
                          <input type="number" step="0.01" min="0" v-model="formData.out_stock">
                      </div>
                      
                      <div class="col">
                          <span>В наявності</span>
                          <input type="number" min="0" v-model="formData.in_stock">
                      </div>

                  </div>
                  </div>

                <button type="submit" class="purpur">Зберегти</button>

            </form>
            
        </div>
    </div>    
</template>
  
<script>
    export default {
        props: [          
          'dataEdit'
        ],

        data() {
            var pdata = {
                formData: {
                    id: false,
                    name: '',
                    qtt: '',
                    item: '',
                    price: '',
                    out_price: '',
                    sum: '',
                    out_stock: '',
                    in_stock: ''
                },
                h1: 'Додати рядок',
                showPopup : false,
                editedItem: null,
                validation: true,
            };

            this.editedItem = { ...this.dataEdit };
            if( Object.keys(this.editedItem).length > 0 ) {
                pdata.formData = this.editedItem;
                if( this.editedItem.id ){
                    pdata.h1 = "Редагування \"" + pdata.formData.name + "\"";                
                }
            }

            return pdata;
        },

        methods: {
            closePopup() {
                if( confirm("Зберегти зміни?") ){
                    this.submitForm(event);
                } else {
                    this.$emit('closePopup');
                }
            },
            submitForm(event) {
                event.preventDefault();

                if( this.validateForm(this.formData) ){
                    this.$emit('popupData', this.formData);
                    this.$emit('closePopup');
                } else {
                    alert('Не всі поля заповненні');
                }
            },
            validateForm(data) {
                for(const item in data){
                    this.validation = true;
                    if( data[item] == '' && item != 'id' ){
                        this.validation = false;
                        break;
                    }
                }
                return this.validation;
            },
        },
    };
</script>
  
<style>
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }
  .column-wrap{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  } 
  .column-wrap .column{
    padding: 20px;
  }
  .col{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  .col input,
  .col select{    
    border: 1px solid #D0D5DD;
    height: 40px;
    border-radius: 10px;
    padding-left: 15px;
    font-size: 19px;
  }
  .col span{
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 15px;
    color: #344054;
  }
  .x{
    float: right;
    cursor: pointer;
  }  
</style>
