import Goods from './Goods.vue';
import GoodsAdd from './add.vue';
import GoodsContainer from './GoodsContainer.vue';

const returns = {
    path: '/stock/returns',
    name: 'returns',
    meta:{
        h1: 'Повернення',
    },
    children:[
      {
        path: '/stock/returns',
        name: 'returns',
        component: Goods
      },
      {
        path: 'add',
        name: 'returns_add',
        component: GoodsAdd
      }
    ],
    component: GoodsContainer,
}

export default returns