<template>
    <BreadCrumbs/>
    <div class="row content-header">
        <h1>Додати: {{ dataRecord.number }}</h1>
        <div class="search">
            <button @click="() => {this.$router.replace('/stock/invoice-outgoing')}">Скасувати</button>
            <button class="purpur" @click="save">Зберегти</button>
        </div>
    </div>
    <div class="content">        
        <div class="block-30" style="margin-top: 30px;">
            <div class="itext">
                <span>Номер документа</span>
                <input type="text" v-model="dataRecord.number">
            </div>
            <div class="itext">
                <span>Дата</span>
                <input type="date" v-model="dataRecord.date">
            </div>
        </div>
        
        <br>
        
        <div class="block-30">            
            <div class="select">
                <span>Поставщик</span>
                <select v-model="dataRecord.shipper_id">
                    <option 
                        v-for="shipper in shippers" 
                        :key="shipper.id"
                        :value="shipper.id"
                    >{{ shipper.name }}</option>
                </select>
            </div>
        </div>
        <div class="table">
            <div class="wrap">
                <table>
                <thead>
                    <tr>
                        <td style="width:3%;"><input type="checkbox"></td>
                        <td>№ <i id="sort" style="padding-left:10px;" class="fa fa-sort-numeric-asc"></i></td>
                        <td>Товар</td>
                        <td>К-ть</td>
                        <td>Од.вим</td>
                        <td>Ціна з ПДВ</td>
                        <td>Вихідна ціна %</td>
                        <td>% Вручну</td>
                        <td>Ціна складу</td>
                        <td>В наявності</td>
                        <td><i class="fa fa-pencil"></i></td>
                        <td><i class="fa fa-trash-o"></i></td>
                    </tr>
                </thead>
                <tbody>                
                    <tr v-for="data in formData" :key="data.key">
                        <td><input type="checkbox"></td>
                        <td>{{ data.key }}</td>
                        <td>{{ data.name }}</td>
                        <td>{{ data.qtt }}</td>
                        <td>{{ data.item }}</td>
                        <td>{{ data.price }}</td>
                        <td>{{ data.out_price }}</td>
                        <td>{{ data.sum }}</td>
                        <td>{{ data.out_stock }}</td>
                        <td>{{ data.in_stock }}</td>
                        <td @click="openPopup(data)"><i class="fa fa-pencil"></i></td>
                        <td @click="removeFormData(data.key)"><i class="fa fa-trash-o"></i></td>
                    </tr>                
                </tbody>            
                </table>
                <a @click="openPopup(null)">Додати рядок</a>
            </div>
        </div>
    </div>

    <popupFormAdd
        v-if="showPopup"
        @popupData="handlePopupData"          
        @closePopup="closePopup"
        :dataEdit="formDataEdit"
    />

</template>

<style scoped>
    .block-30{
        max-width: 40%;
        padding: 0 30px;
        display: flex;
        justify-content: space-between;
    }
    .itext{
        width: 50%;
        text-align: left;
        margin: 0 20px;
    }
    span{
        display: block;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 16px;
        color: #344054;
    }
    .itext input{
        width: 90%;
        border: 1px solid #D0D5DD;
        height: 40px;        
        border-radius: 10px;
        padding-left: 15px;
        font-size: 19px;        
    }
    .itext input[type="date"]{
        padding-right: 15px;
    }
    .select{
        width: 100%;
        text-align: left;
        margin: 0 20px;
    }
    .select select{
        width: 101%;
        border: 1px solid #D0D5DD;
        height: 44px;
        border-radius: 10px;
        padding-left: 15px;
        font-size: 19px;
    }
    .table{
        margin-top: 30px;
        padding: 0 40px;        
    }
    .table .wrap{
        border: 1px solid #D0D5DD;
        border-radius: 10px;        
        margin-left: 10px;
        overflow: hidden;
    }
    .table .wrap a{
        display: block;
        padding: 15px 0 15px 5px;
        text-align: left;
        margin-left: 45px;
        color: #7827DA;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
        width: 10%;
    }
    .fa-trash-o,
    .fa-pencil
    {
        font-size: 21px;
    }
</style>

<script>
    import BreadCrumbs from '@/components/Breadcrumbs.vue'    
    import popupFormAdd from '@/components/popupForm.vue';   

    export default {
        name: 'StockInvoicesAdd',
        components: {            
            BreadCrumbs,
            popupFormAdd
        },
        data(){
            this.$route.meta.h1 = "";
            this.$route.meta.h1 = 'Нова накладна';
            return {
                //childs list                
                formData: [
                    /*{
                        key: Date.now(),
                        name: 'тест товар',
                        qtt: '11',
                        item: 'шт',
                        price: '123',
                        out_price: '321',
                        sum: '144',
                        out_stock: '111',
                        in_stock: '145',
                        invoices_id: null 
                    },*/
                ],
                
                //shippers list axios
                shippers: [],

                //data Record
                dataRecord: {
                    number: '',
                    date: new Date().toISOString().slice(0, 10),
                    shipper_id: null,
                    type: 'outgoing'                    
                },                    

                //common                
                formDataEdit:null,
                selectedItem: null,
                showPopup : false
            }
        },
        methods:{
            //...add\edit
            handlePopupData(data){
                if( data.key ) {
                    const index = this.formData.findIndex(item => item.key === data.key);
                    if (index !== -1) {
                        this.formData.splice(index, 1, data);
                    }
                } else {
                    this.formData.push({
                        key: Date.now(),
                        name: data.name,
                        qtt: data.qtt,
                        item: data.item,
                        price: data.price,
                        out_price: data.out_price,
                        sum: data.sum,
                        out_stock: data.out_stock,
                        in_stock: data.in_stock
                    });                
                }
            },
            removeFormData(key) {
                if( confirm("Дійсно видалити?") ){
                    this.formData = this.formData.filter(data => data.key !== key);
                }
            },
            //...add\edit
            openPopup(data){
                this.formDataEdit = { ...data };
                this.showPopup = true;                
            },
            closePopup() {
                this.showPopup = false;
                this.formDataEdit = [];
            },
            // save invoice
            save(){
                this.$axios.post('/invoices', this.dataRecord).then(response => {                    
                    if(this.formData.length > 0) {
                        this.formData.forEach((e)=>{
                            e.invoices_id = response.data.id;
                        });                    
                        this.$axios.post('/invoices-items', this.formData).then(response => {
                            console.log(response.data);
                        });
                    }
                    this.$router.replace('/stock/invoice-outgoing');
                });
            },            
        },
        mounted(){
            //shipper
            this.$axios.get('/shipper').then(response => {
                this.shippers = response.data.items;                
                this.dataRecord.shipper_id = response.data.items[0].id;
            });
        }
    }
</script>