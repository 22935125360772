<template>
    <router-view/>    
</template>

<script>
    export default {
        name: 'customersContainer',
        data(){
            return {

            }
        }
    }
</script>