<template>
    <BreadCrumbs/>
    <div class="row content-header">
        <h1>{{ this.$route.meta.h1 }} ({{ totalCount }})</h1>
        <div class="search">
            
            <select v-model="categorySelected">
                <option value="0">Всі категорії</option>
                <option v-for="category in categories" :value="category.id" :key="category.id">{{ category.name }}</option>
            </select>

            <i class="fa fa-search" @click="search"></i>
            <input v-model.trim="searchText" v-on:keyup.enter="search" type="text" placeholder="Пошук">
            <button class="remove" @click="rem()"><i class="fa fa-trash-o"></i></button>
            <button class="purpur" @click="add()"><i class="fa fa-plus"></i>Додати</button>
        </div>
    </div>

    <!-- {{ checkedItems }} -->
 
    <!-- Pagination -->
    <PaginationItems 
        :ItemsObject="this.pagination" 
        @PaginationData="paginationHandler"                 
    />
    
    <!-- loading -->
    <div v-if="loading" class="lds-dual-ring"></div>

    <!-- items -->
    <ProductsList 
        :TablesItems="this.TablesItems"
        @checkedItems="checkedItemsHandler"
    />

    <!-- Pagination -->
    <PaginationItems 
        :ItemsObject="this.pagination" 
        @PaginationData="paginationHandler" 
    />

</template>

<style>
    .body{
        background-color: #F9FAFB;
    }
    .search{
        display: flex;
        align-items: center;
    }
    .search button.remove{
        font-size: 20px;
        max-height: 44px;
        width: 45px;
    }
    .search button.remove i{
        padding: 0;
        font-size: 25px;
    }
    .search select {
        border: 1px solid #D0D5DD;
        height: 40px;
        width: 280px;
        border-radius: 10px;
        padding-left: 10px;
        font-size: 19px;
    }
</style>

<script>
    
    import BreadCrumbs from '@/components/Breadcrumbs.vue'
    import ProductsList from '@/components/ProductsList.vue'
    import PaginationItems from '@/components/PaginationItems.vue'

    import { sortedCategories } from '@/functions';

    export default {
        name: 'productsView',
        components: {            
            BreadCrumbs,             
            ProductsList,
            PaginationItems
        },
        data(){            
            return {
                loading: false,
                searchText: '',
                pagination: {},
                totalCount: 0,
                productCount: 0,
                categories: [],
                categorySelected: 0,
                anyfields: [],
                checkedItems: [],
                TablesItems: [
                    // {
                    //     id:1,
                    //     number:'00000',
                    //     img:'/assets/img/detal1.jpg',
                    //     name:'Конденсатор високовольтний керамічний 33 pF 2 kV, SL, 10%'
                    // },
                ],                
            }
        },
        created() {
            this.fetchData();
        },
        methods:{
            fetchData() {
                this.loading = true;

                //category
                this.$axios.get('/categories/tree')
                    .then(response => {
                        this.categories = sortedCategories(response.data);                    
                        this.loading = false;
                    })
                    .catch(error => {
                        console.error(error);
                    });

                //products
                this.$axios.get('/products?expand=anyfields')
                    .then(response => {
                        this.TablesItems = response.data.items;
                        this.pagination = {
                            links: response.data._links,
                            meta: response.data._meta,
                        };
                        this.totalCount = this.pagination.meta.totalCount;
                        this.loading = false;
                    })
                    .catch(error => {
                        console.error(error);
                    });
            },
            fetchOnCategory(category_id){
                //products
                this.loading = true;
                this.$axios.get('/products?expand=anyfields&filter[category_id]=' + category_id)
                    .then(response => {
                        this.TablesItems = response.data.items;
                        this.pagination = {
                            links: response.data._links,
                            meta: response.data._meta,
                        };
                        this.totalCount = this.pagination.meta.totalCount;
                        this.loading = false;
                    })
                    .catch(error => {
                        console.error(error);
                    });
            },            
            checkedItemsHandler(data) { 
                this.checkedItems = data;
            },
            //check link
            paginationHandler(link) {
                this.loading = true;
                this.$axios.get(link)
                    .then(response => {
                        this.TablesItems = response.data.items;
                        this.pagination = {
                            links: response.data._links,
                            meta: response.data._meta,
                        };
                        this.totalCount = this.pagination.meta.totalCount;
                        this.loading = false;
                    })                    
                    .catch(error => {
                        console.error(error);
                    });                                    
            },
            add(){                
                this.$router.replace({ path: this.$route.href + "/add" });
            },
            rem(){
                if( this.checkedItems.length > 0 && confirm("Видалити элементів: " + this.checkedItems.length + " ?!") ) {
                    var count = 0;
                    this.checkedItems.forEach( (el) => {
                        this.$axios.delete('/products/' + el)
                            .then(response => {                                
                                count++;

                                if( this.checkedItems.length == count ) {
                                    console.log( response );
                                    this.checkedItems = [];
                                    this.fetchData();
                                }
                            })
                            .catch(error => {
                                console.error(error);
                            });                                    
                    });
                }
            }
        },
        watch:{
            categorySelected: function(id){
                if( id == 0 ) {
                    this.fetchData();
                } else {
                    this.fetchOnCategory(id);
                }                
            }
        }
    }
</script>