<template>
    <div class="menu" v-bind:class="{ show: isShowNav }">
        <div class="row">
            <nav>
                <router-link to="/"><img src="../assets/logo.png"></router-link>
                <router-link to="/stock" exact>Склад</router-link>
                <router-link to="/cashbox">Каса</router-link>
                <router-link to="/inventory">Переоблік</router-link>
                <router-link to="/dictionary">Довідники</router-link>
                <router-link to="/" disabled class="disabled">Інтернет-магазин</router-link>
            </nav>
        </div>
        <div class="row" v-if="isAuthenticated()">
            <nav class="right-icon">
                <li><router-link to="/"><i class="fa fa-gear"></i></router-link></li>
                <li><router-link to="/"><i class="fa fa-bell"></i></router-link></li>
                <!-- <li><a href="#" @click="toggleShowNav"><i class="fa" v-bind:class="[isShowNav ? 'fa-remove' : 'fa-navicon']"></i></a></li> -->
                <li>
                    <div class="hr" @click="toggleShowNav" v-bind:class="{ change: isShowNav }">
                        <div class="bar1"></div>
                        <div class="bar2"></div>
                        <div class="bar3"></div>
                    </div>
                </li>
                <li><a class="dropdown circle" @click="toggleShowDropdown"><img src="../assets/logo.png"></a></li>
                <li class="dropdown-container" v-bind:style="[isShowDropdown ? 'display:flex' : 'display:none']">
                    <div class="dropdown-head">
                        <div class="col">
                            <a class="dropdown circle"><img src="../assets/logo.png"></a>
                        </div>
                        <div class="col">
                            <span>Фамилия имя пользователя</span>
                            <p>test@test.test</p>
                        </div>
                    </div>
                    <hr>
                    <a href="#">Профіль</a>
                    <a href="#">Налаштування</a>
                    <a href="#">Підтримка</a>
                    <a href="#">Документація</a>
                    <hr>
                    <a href="#" @click="logOut">Вийти</a>
                </li>
            </nav>
        </div>
    </div>
</template>

<style>
.menu{
    height: 72px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    flex-direction: row;
    color: #000;
    justify-content: space-between;
}
.menu .row nav{
    padding: 0 30px;
    display: flex;
    align-items: center;    
}
nav a{
    /*font-family: 'Inter';*/
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #344054;
    text-decoration: none;    
    padding: 0.5rem 1rem;
    margin-right: 4px;
    transition: all .3s ease-in;
}
nav a:first-child{
    margin-top: 5px;
    margin-right: 32px;
}
nav a.router-link-active:nth-child(n+2),
nav a:hover:nth-child(n+2)
{
    color: #7827DA;
    background: #F6F3FF;
    border-radius: 6px;    

}
nav a.disabled:hover:nth-child(n+2),
nav a.disabled{
    color: #D0D5DD!important;
    background: transparent!important;
    border-radius: 0!important;
    cursor: default!important;
    pointer-events: none!important;
}
nav.right-icon{
    list-style: none;
}
nav.right-icon ul{

}
nav.right-icon ul li{

}
nav.right-icon i{
    color: #667085;
    font-size: 20px;
}
nav.right-icon a{
    margin: 0 5px;
    padding-top: 0;
    padding-left: 4px;
}
nav.right-icon .dropdown.circle{
    width: 40px;
    height: 40px;
    display: block;
    border: 1px solid;
    padding: 0;
    margin: 0 5px 0 8px;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
}
nav.right-icon .dropdown.circle img{
    width: 40px;
    height: 40px;
}
nav.right-icon .fa-remove,
nav.right-icon .fa-navicon{
    display:none;
}
.dropdown-container{
    position: absolute;
    right: 35px;
    border: 1px solid #D0D5DD;
    top: 60px;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 240px;
}
.dropdown-container a{
    width: 90%;
    text-align: left;
}
.dropdown-container a:hover{    
    background: unset!important;
}
.dropdown-container hr{
    width: 100%;    
    border: 1px solid #D0D5DD;
}
.dropdown-head{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 12px;
    padding-top: 10px;
    cursor: pointer;
}
.dropdown-head .col{
    padding-right: 10px;
}
.dropdown-head .col p{
    margin: 0;
}

/** responsive  */
@media screen and (max-width: 1040px){

    .menu {
        height: 60px;
        background-color: #FFF;
        display: flex;
        align-items: center;
        flex-direction: column;
        color: #000;
        justify-content: space-between;
        overflow: hidden;
        transition: all .3s ease-in;
        max-height: 300px;
    }

    .menu.show{
        height: 100%;
    }

    .menu .row{
        background-color: #fff;
        width: 100%;
        position: relative;
        /* padding-bottom: 30px; */
    }

    .menu .row nav {
        padding: 0px 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    nav a.router-link-active:nth-child(n+2), nav a:hover:nth-child(n+2) {

    }

    .menu .row:nth-child(n+2){
        width: 100%;
        position: absolute;
        padding-top: 20px;
        background-color:transparent;
    }

    nav.right-icon {
        display: flex;
        flex-direction: row!important;
        align-items: center;
        justify-content: flex-end;
        padding: 0px 15px;
    }

    
    nav.right-icon .fa-remove, 
    nav.right-icon .fa-navicon{
        position: relative;
        right: calc(100%/-20);
        display:inline-block;
        top: -3px;
        right: -15px;
    }
    nav.right-icon .fa-navicon{
        top: -5px;
        font-size: 30px;
    }

    nav.right-icon li:last-child{
        display:none;
    }

    nav.right-icon .dropdown.circle {
        top: -10px;
        position: relative;
        left: 5px;
    }

    .fa-remove{
        font-size: 20px;
        color: #7f56d9;
        border-radius: 5px;
        border: 1px solid;
        /*padding: 1px 3px;*/
        padding: 3px 5px;
    }

    .hr {
        display: inline-block;
        cursor: pointer;
    }

    .bar1, .bar2, .bar3 {
        width: 35px;
        height: 5px;
        background-color: #667085;
        margin: 6px 0;
        transition: 0.4s;
        top: -10px;
        position: relative;
    }

    .change .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
    }

    .change .bar2 {opacity: 0;}

    .change .bar3 {
    transform: translate(0, -11px) rotate(45deg);
    }

}

</style>

<script>
    import { logout } from '@/auth';    
    import { isAuthenticated } from '@/auth';

    export default{ 
        name:'mainMenu',
        data() {
            return {
                isShowNav: false,
                isShowDropdown: false
            }
        },
        methods:{
            toggleShowNav(){
                this.isShowNav ? 
                    this.isShowNav = false : 
                        this.isShowNav = true;
            },
            toggleShowDropdown(){
                this.isShowDropdown ? 
                    this.isShowDropdown = false : 
                        this.isShowDropdown = true;
            },
            logOut(){
                logout();
            },
            isAuthenticated
        }
    }
</script>
