<template>
    <div class="popup-overlay">
        <div class="popup-content">
            <i @click="closePopup" class="x fa fa-times"></i>
            <h3>{{ popupData.name }}</h3>
            <div class="column-wrap">
                <div class="column" style="padding-left:0; min-width:250px;">
                    <img src="@/assets/img/detal1_big.jpg">
                </div>
                <div class="column">
                    <div class="item">
                        <span>Артикул:</span>
                        <p>{{ art }}&nbsp;&nbsp;&nbsp;<i @click="CopyClipboard(this.art)" class="fa fa-files-o"></i></p>
                    </div>
                    <div class="item">
                        <span>Код товару:</span>
                        <p>{{ code }}&nbsp;&nbsp;&nbsp;<i @click="CopyClipboard(this.code)" class="fa fa-files-o"></i></p>
                    </div>
                    <div class="item">
                        <span>Виробник:</span>
                        <p>China</p>
                    </div>
                </div>               
            </div>
            <hr>
            <button type="submit" style="float:right;margin-top:10px;"><i class="fa fa-pencil"></i>Редагувати</button>
            <!-- {{ popupData }} -->
        </div>
    </div>    
</template>
  
<style>
  .popup-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content{
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }
  .column-wrap{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  } 
  .column-wrap .column{
    padding: 20px;
  }
  .column-wrap .column img{
    max-width: 250px;
    max-height: 250px;
  }
  .item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 5px;
  }
  .item span{
    color:#667085;
  }
  .item p{
    color: #101828;
    font-size: 16px;
    font-weight: bold;
    margin: 8px 0;
  }
  .fa-files-o{
    color: #667085;
    font-size: 18px;
    cursor: pointer;
  }
  .x{
    float: right;
    cursor: pointer;
  }  
</style>

<script>
    export default {
        props: [          
          'popupData'
        ],
        data() {
            return {
                art : 'CVC32SL',
                code : '0001'
            };
        },
        methods: {
            closePopup() {
                this.$emit('closePopup');
            },
            CopyClipboard(text){
                try{
                    navigator.clipboard.writeText(text);
                } catch(e){
                    console.log(e);
                }
            }
        },
        mounted(){
            //console.log(this.popupData);
        }
    };
</script>