<template>
    <form class="table-wrapper">
        <table>
            <thead>
                <tr>
                    <td style="width:3%;"><input type="checkbox" @click="selectAll" v-model="allSelected"></td>
                    <td>Назва категорії&nbsp;&nbsp;&nbsp;<i class="fa fa-sort-alpha-asc"></i></td>
                    <td class="tbl-buttons"><i class="fa fa-pencil"></i></td>
                </tr>                
            </thead>
            <tbody>
                <tr v-for="item in TablesItems" :key="item.id">
                    <td><input type="checkbox" @click="select" v-model="itemIds" :value="item.id"></td>
                    <td style="width: 100%;">
                        <a class="child">{{ item.name }}</a>
                        <a class="child" v-for="child in item.items" :key="child.id">{{ child.name }}</a>                        
                    </td>
                    <td class="tbl-buttons"><i class="fa fa-pencil"></i></td>
                </tr>
            </tbody>            
        </table>
    </form>
    
    <!-- {{ itemIds }} -->

</template>

<style>
.table-wrapper{
    padding: 0 40px;
}

.table-wrapper a{
    color: #000;
}

thead tr td{
    color:#667085;
}

table {
    width: 100%;
    border-collapse: collapse;  
}
th, td {
    vertical-align: middle;
    text-align: left;
    padding: 7px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}
th {
    font-weight: bold;
}
td p {
    margin: 0;
    color:#667085;
}
tr th:first-child,
tr td:first-child {    
    text-align: left;
    max-height: 52px;    
}
td:nth-child(3) {
    color:#667085;    
}
tbody tr:nth-child(even) {
  background-color:#F9FAFB;
}
tbody tr:nth-child(odd) {
  background-color: #fff;
  border-top: 2px solid #EAECF0;
  border-bottom: 2px solid #EAECF0;
}
input[type=checkbox]{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.tbl-buttons{
    text-align: center;
    font-size: 22px;
    color: #667085;
    cursor: pointer;
}
.tbl-buttons.right{
    text-align: right;
}
.child::before{
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    content: " \f105 ";
    padding: 0 8px;
}
.child:first-child::before{
    display: none;
}
</style>

<script>    
    export default{ 
        name:'CategoriesList',
        data() {            
            return {
                allSelected: false,
                itemIds: [],
            }
        },
        props:{
            TablesItems:Object
        },
        methods:{
            selectAll() {
                this.itemIds = [];

                if (!this.allSelected) {
                    this.TablesItems.forEach(element => {
                        this.itemIds.push(element.id.toString());
                    });                    
                }
            },
            select(){
                this.allSelected = false;
            }
        },
    }
</script>