//inventory
import InventoryView from './Inventory.vue';
import InventoryContainer from './InventoryContainer.vue';

const inventory =
  {
    path: '/inventory',
    name: 'inventory',
    meta:{
      h1: 'Переоблік'
    },
    children:[
      {
        path: '',
        name: 'inventory',
        component: InventoryView
      },
      //new modules this
    ],
    component: InventoryContainer
  }

  export default inventory