<template>
    <div class="panel-buttons">
        <router-link v-for="item in CardItems" :key="item.id" :to="item.path" class="main-button item">
            <div class="item-header">
                <i :class="item.icon"></i>
                <span>{{ item.caption }}</span>
            </div>
            <h1>{{ item.count }}</h1>
            <a>Переглянути&nbsp;&nbsp;&nbsp;&nbsp;</a>
        </router-link>
    </div>
</template>

<style>
    /** cards */
    .content .panel-buttons {
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 0 40px;
    }
    .content .panel-buttons .item{
        width: calc(93%/3);
        height: 195px;
        /*max-width: 370px;*/
        max-width: 450px;
        background: #FFFFFF;
        border: 1px solid #EAECF0;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        color: #000;
        padding: 0;
    }

    .content .panel-buttons .item h1{
        margin: 15px 10px;
        position: relative;
        top: -15px;
        font-weight: bold;
        font-size: 35px;
        left: 15px;
    }

    .content .panel-buttons .item a{
        display: block;
        text-align: right;
        width: 100%;
        padding-top: 15px;
        border-top: 1px solid #EAECF0;
        color: #7827DA;
        font-weight: 600;
        position: relative;
        top: -15px;
    }
    

    .content .panel-buttons .item span{
        color:#000;
        font-size: 22px;        
    }

    .content .main-button i{
        background: #F4EBFF;
        border: 8px solid #F9F5FF;
        border-radius: 35px;
        color: #7827DA;
        width: 44px;
        margin: 15px;
    }
    /** end cards */
</style>

<script>
    export default{ 
        name:'CardsItems',
        data() {
            return {
                
            }
        },
        props:{
            CardItems:Object
        },
        methods:{

        }
    }
</script>