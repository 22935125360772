<template>
    <MainMenu/>
    <div class="home-row">
        <h1 style="color:#fff">{{ $route.meta.h1 }}</h1>
        <div class="panel-buttons">
            <router-link to="/stock" class="main-button purpur"><i class="fa fa-cube"></i><span>Склад</span></router-link>
            <router-link to="/cashbox" class="main-button green"><i class="fa fa-line-chart"></i><span>Каса</span></router-link>
        </div>
    </div>
</template>

<style>
    .body.home {
        background-image: url(../../assets/background.png), linear-gradient(45deg, #42307d 0%, #7F56D9 100%);
        background-position: top;
        background-repeat: no-repeat;
    }
    .panel-buttons{
        display: flex;
        justify-content: center;
    }
    .main-button{
        width: 270px;
        height: 142px;
        border-radius: 12px;
        margin: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        padding-left: 30px;
    }
    .main-button i{
        background-color: #F4EBFF;
        padding: 11px;
        border-radius: 10px;
        font-size: 25px;
        max-width: 25px;
    }
    .main-button span{
        font-size: 30px;
        color:#FFF;
    }
    .main-button.purpur{
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
        background: #7F56D9;
        color: #7F56D9;
    }
    .main-button.green{
        color: #039855;
        background: #039855;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    }
</style>

<script>
    import MainMenu from '../../components/MainMenu.vue';

    export default {
        name: 'HomeView',
        components: {
            MainMenu
        }
    }
</script>

