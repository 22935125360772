<template>    
    <BreadCrumbs/>
    <div class="row content-header">
        <h1>Довідники</h1>
        <div class="search">
            <i class="fa fa-search"></i>
            <input type="text" name="search" placeholder="Пошук">                
        </div>
    </div>
    <!-- loading -->
    <div v-if="loading" class="lds-dual-ring"></div>

    <CardsItems :CardItems="this.CardItems" />
</template>

<style>
    .body.stock {
        background-color: #F9FAFB;
    }
    .content-header{
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 10px 40px;
        justify-content: space-between;        
    }
    .content-header h1{
        text-align: left;
        padding: 0;
        margin: 0px 20px 0px 10px;
    }
    .search input{
        border: 1px solid #D0D5DD;
        height: 40px;        
        width: 280px;
        border-radius: 10px;
        padding-left: 40px;
        font-size: 19px;
    }
    .search .fa-search{
        position: relative;
        left: 30px;        
        font-size: 18px;
        cursor:pointer;
    }
    button{
        border: 1px solid #D0D5DD;
        height: 44px;
        width: 120px;
        border-radius: 10px;    
        font-size: 16px;
        margin-left: 16px;
        cursor: pointer;
        background-color: #FFF;
        color: #344054;
    }
    button i{
        padding-right: 7px;
    }
    button.purpur {
        background-color: #7F56D9;
        color:#FFF;
    }
</style>

<script>
    import BreadCrumbs from '@/components/Breadcrumbs.vue'
    import CardsItems from '@/components/Cards.vue'

    export default {
        name: 'DictionaryView',
        components: {            
            BreadCrumbs,
            CardsItems
        },
        data(){            
            return {
                loading: false,
                CardItems:[
                    {id:1, path:'/dictionary/categories', count: 10, icon: 'fa fa-th-large', caption: 'Категорії товарів'},
                    {id:1, path:'/dictionary/products', count: 10, icon: 'fa fa-database', caption: 'Товари'},
                    {id:1, path:'/dictionary/suppliers', count: 10, icon: 'fa fa-truck', caption: 'Постачальники'},
                    {id:1, path:'/dictionary/customers', count: 10, icon: 'fa fa-briefcase', caption: 'Покупці'},
                ]
            }
        },
        mounted(){
            //this.loading = true;

            /*this.$axios.get('/invoices/count').then(response => {
                //console.log(response);
                response.data.forEach(element => {
                    switch(element.type){
                        case 'purchase':
                            this.CardItems.push({id:element.id, path:'/stock/invoice-purchase', count: element.count, icon: 'fa fa-file-text-o', caption: 'Прибуткові накладні'});
                            break;
                        case 'outgoing':
                            this.CardItems.push({id:element.id, path:'/stock/invoice-outgoing', count: element.count, icon: 'fa fa-shopping-basket', caption: 'Видаткові накладні'});
                            break;
                    }
                });
            })
            .finally(() => {
                this.loading = false;
            });*/
        }
    }
</script>