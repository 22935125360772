<template>
    <div class="pagination" :style="[!pageCount ? 'display:none' : 'display:flex' ]">        
        <button @click="backLink" :class="[pageBackLink ? '' : 'disbled']"><i class="fa fa-arrow-left"></i>Назад</button>        
        <div class="center-text"><b>Сторінка {{ currentPage }} з {{ pageCount }}</b></div>        
        <button @click="nextLink" :class="[pageNextLink ? '' : 'disbled']">Вперед<i class="fa fa-arrow-right"></i></button>
    </div>
</template>

<style>
    .pagination{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 40px 40px;
        align-items: center;
    }
    .pagination button{
        margin: 0;
    }
    .pagination button i {    
        padding: 0 10px;
    }
    .pagination button.disbled{
        position: relative;
        z-index: 0;
        opacity: 0.5;
        cursor: default;
    }
</style>

<script>
    export default{  
        name:'PaginationItems',
        data() {
            return {
                currentPage: 0,
                pageCount: 0,
                pageNextLink: false,
                pageBackLink: false,                
            }
        },
        props:{
            ItemsObject:Object            
        },
        methods:{
            nextLink(){
                if(this.pageNextLink)
                    this.$emit('PaginationData', this.pageNextLink);
                
            },
            backLink(){
                if(this.pageBackLink)
                    this.$emit('PaginationData', this.pageBackLink);
            },
        },        
        async updated() {
            //console.log(this.ItemsObject.meta);
            //console.log(this.pageBackLink, this.pageNextLink);
            if (!this.data) {
                this.currentPage = this.ItemsObject.meta.currentPage;
                this.pageCount = this.ItemsObject.meta.pageCount;

                if( !this.ItemsObject.links.next )
                    this.pageNextLink = false;
                else
                    this.pageNextLink = this.ItemsObject.links.next.href;                
                if( !this.ItemsObject.links.prev )
                    this.pageBackLink = false;
                else
                    this.pageBackLink = this.ItemsObject.links.prev.href;
            }
        }
    }
</script>