//сash
import CashView from './Cash.vue';
import CashContainer from './CashContainer.vue';

const cashbox =
  {
    path: '/cashbox',
    name: 'cashbox',
    meta:{
      h1: 'Каса'
    },
    children:[
      {
        path: '',
        name: 'cashbox',
        component: CashView
      },
      
    ],
    component: CashContainer
  }

  export default cashbox