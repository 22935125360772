export function sortedCategories(data) {
    const flatCategories = [];
    const flatten = (category, parentPath) => {
        const path = parentPath ? `${parentPath} > ${category.name}` : category.name;                
        flatCategories.push({ ...category, name:path, id:category.id });
        category.children.forEach(child => flatten(child, path));
    };
    data.forEach(category => flatten(category, ''));
    //flatCategories.sort((a, b) => a.path.localeCompare(b.path));
    return flatCategories;
}

//====================================================================================

export function CategoryBreadcrumbsCollect(data) {
    function collectChildren(node) {
        const flattened = [];
        if (node.parent_id > 0) {
            flattened.push({
                "id": node.id,
                "parent_id": node.parent_id,
                "name": node.name,
                "description": node.description
            });
        }
        for (const child of node.children) {
            flattened.push(...collectChildren(child));
        }
  
        return flattened;
    }
  
    const topLevelElements = data.filter(item => item.parent_id === 0);
    const flattenedData = topLevelElements.map(element => {
        return {
            ...element,
            items: collectChildren(element)
        };
    });
  
    return flattenedData;  
}

//====================================================================================
//====================================================================================