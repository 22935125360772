//users
import Users from './Users.vue';
import UsersContainer from './UsersContainer.vue';

//auth
import auth from './authView'

const users =
  {
    path: '/users',
    name: 'users',
    meta:{
      h1: 'Користувачі'
    },
    children:[
      {
        path: '',
        name: 'users',
        component: Users
      },
      //auth
      {
        path: '/login',
        name: 'auth',
        meta:{
          h1: 'Авторизація'
        },
        component: auth
      },

      //new module this
    ],
    component: UsersContainer
  }

  export default users