<template>
    <BreadCrumbs/>
    <div class="row content-header">
        <h1>{{ this.$route.meta.h1 }} ({{ TablesItems.length }})</h1>
        <div class="search">
            <i class="fa fa-search" @click="search"></i>
            <input v-model.trim="searchText" v-on:keyup.enter="search" type="text" placeholder="Пошук">
            <button class="remove"><i class="fa fa-trash-o"></i></button>
            <button class="purpur" @click="add()"><i class="fa fa-plus"></i>Додати</button>
        </div>
    </div>
    
    <!-- loading -->
    <div v-if="loading" class="lds-dual-ring"></div>
    
    <!-- items -->
    <CategoriesList 
        :TablesItems="this.TablesItems" 
        v-if="!loading" 
    />

    <!-- Pagination -->
    <PaginationItems 
        :ItemsObject="this.pagination" 
        @PaginationData="PaginationData" 
        v-if="!loading"
    />

</template>

<style>
    .body{
        background-color: #F9FAFB;
    }
    .search{
        display: flex;
        align-items: center;
    }
    .search button.remove{
        font-size: 20px;
        max-height: 44px;
        width: 45px;
    }
    .search button.remove i{
        padding: 0;
        font-size: 25px;
    }
</style>

<script>
    
    import BreadCrumbs from '@/components/Breadcrumbs.vue'
    import CategoriesList from '@/components/CategoriesList.vue'
    import PaginationItems from '@/components/PaginationItems.vue'

    import { sortedCategories } from '@/functions';

    export default {
        name: 'categoriesView',
        components: {            
            BreadCrumbs,             
            CategoriesList,
            PaginationItems
        },
        data(){            
            return {
                searchText: '',
                pagination:{},
                
                loading: false,
                
                TablesItems:[
                    // {
                    //     id: '1',
                    //     name: 'Електронні компоненти',
                    //     items: [
                    //         {
                    //             id: '12',
                    //             name: 'Транзистори'
                    //         },
                    //         {
                    //             id: '13',
                    //             name: 'Транзистори n-канальні з ізольованим затвором'
                    //         },
                    //     ]
                    // },
                ],                
            }
        },
        created() {
            this.loading = true;
            
            this.$axios.get('/categories/tree')
                .then(response => {
                    this.TablesItems = sortedCategories(response.data);
                    this.loading = false;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        methods:{
            add(){                
                this.$router.replace({ path: this.$route.href + "/add" });
            }
        }
    }
</script>