import ProductsContainer from './ProductsContainer.vue';
import Products from './Products.vue';
import ProductsAdd from './add.vue';
import ProductsEdit from './add.vue';//!!!!!!!!!

const products = {
    path: '/dictionary/products',
    name: 'products',
    meta:{
        h1: 'Товари',
    },
    children:[
      {
        path: '/dictionary/products',
        name: 'products',
        component: Products
      },
      {
        path: ':product_id',
        name: 'products_id',
        component: Products
      },
      {
        path: ':product_id/edit',
        name: 'products_edit',
        component: ProductsEdit
      },      
      {
        path: 'add',
        name: 'products_add',
        component: ProductsAdd
      }
    ],
    component: ProductsContainer,
}

export default products