import SuppliersContainer from './SuppliersContainer.vue';
import Suppliers from './Suppliers.vue';
import SuppliersAdd from './add.vue';

const suppliers = {
    path: '/dictionary/suppliers',
    name: 'suppliers',
    meta:{
        h1: 'suppliers',
    },
    children:[
      {
        path: '/dictionary/suppliers',
        name: 'suppliers',
        component: Suppliers
      },
      {
        path: 'add',
        name: 'suppliers_add',
        component: SuppliersAdd
      }
    ],
    component: SuppliersContainer,
}

export default suppliers