import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import axios from 'axios';
import { getToken } from './auth';

const app = createApp(App);
app.use(router);
app.use(store);

//Axios
const api = axios.create({
    //baseURL: 'http://backend/v1',
    //timeout: 1500,
    baseURL: 'https://radio.reactlogic.com/v1',
});

api.interceptors.request.use(async (config) => {
  const token =  await getToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

app.config.globalProperties.$axios = api;

//mount app
app.mount('#app');