<template>
    <nav class="breadcrumbs row">
        <li><RouterLink to="/" class="home"><i class="fa fa-home"></i></RouterLink></li>
        <li v-for="item in items" :key="item.id">
            <i class="fa fa-angle-right" style="color:#D0D5DD" v-if="!item.path"></i>
            <router-link :to="item.path" v-if="item.path">{{ item.name }}</router-link>
        </li>
        <!-- <li> <router-link to="/stock/invoices">invoices</router-link></li> -->
    </nav>       
</template>

<style>
    .breadcrumbs{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 20px 25px;        
    }
    .breadcrumbs li{
        display: inline-block;
    }
    .breadcrumbs a.home{
        margin-right: 0;
        font-size: 22px !important;
        padding: 3px 0.6rem;
    }
    .breadcrumbs a:first-child{
        border-radius: 6px;
        font-weight: bold;
        margin: 0 15px;
        background: #F6F3FF;
        border-radius: 6px;
        font-size: 15px;        
    }
    .breadcrumbs a:hover{
        color: #7827DA;
        background: #F6F3FF;
        border-radius: 6px;
        font-weight: bold;    
    }
    .breadcrumbs a.router-link-exact-active{
        color: #7827DA;
        background: #F6F3FF;
        border-radius: 6px;
        font-weight: bold;
        font-size: 15px;
        margin: 0 15px;
    }

    /** responsive  */
    @media screen and (max-width: 1040px){
        
    }
</style>

<script>
    export default{
        name:'BreadCrumbs',
        data(){            
            return {
                items: this.crumbs(this.$route.matched),
            }
        },
        watch:{
            $route(){
                this.items = this.crumbs(this.$route.matched);                
            }            
        },
        methods:{
            crumbs(matched){
                let c = 0;
                let crumbs = [];

                const tmp = {};
                matched = matched.filter(({path}) =>(!tmp[path] && (tmp[path] = 1)));
                matched.forEach((element,index) => {
                    if( this.$route.name == element.name ){
                        matched[index].meta.h1 = this.$route.meta.h1;
                        matched[index].path = this.$route.path;                        
                    } else {
                        //console.log(this.$route);
                    }
                });

                for(var i=0;i<(matched.length*2);i++){
                    if(i % 2){
                        crumbs[i] = {
                            'id': i,
                            'path': matched[c].path,
                            'name': matched[c].meta.h1
                        };
                        c++;
                    } else {
                        crumbs[i] = {
                            'id': i,
                            'path': false,
                            'name': false
                        };
                    }              
                }
                return crumbs;
            }
        }
    }
</script>