//dictionary
import DictionaryView from './Dictionary.vue';
import DictionaryContainer from './DictionaryContainer.vue';

//dictionary routes
import categories from './categories/routes';
import products from './products/routes';
import customers from './customers/routes';
import suppliers from './suppliers/routes';

const dictionary =
  {
    path: '/dictionary',
    name: 'dictionary',
    meta:{
      h1: 'Довідники'
    },
    children:[
      {
        path: '',
        name: 'dictionary',
        component: DictionaryView
      },
      categories,
      products,
      customers,
      suppliers
    ],
    component: DictionaryContainer
  }

  export default dictionary