import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      dataArray: []
    };
  },
  mutations: {
    setDataArray(state, dataArray) {
      state.dataArray = dataArray;
    }
  },
  actions: {
    setDataArray({ commit }, dataArray) {
      commit('setDataArray', dataArray);
    }
  },
  getters: {
    getDataArray(state) {
      return state.dataArray;
    }
  }
});

export default store;