<template>
    <MainMenu/>
    <div class="content">
        <router-view/>
    </div>    
</template>

<script>    
    import MainMenu from '@/components/MainMenu.vue'

    export default {
        name: 'UsersViewContainer',
        components: {
            MainMenu,            
        },
        data(){
            return {

            }
        }
    }
</script>