<template>  
  <div class="body" v-bind:class="$route.name">    
    <router-view/>        
  </div>
  <!-- <div class="footer" style="padding: 50px;background-color: aqua;">footer</div> -->
</template>

<style>
body{
  margin: 0;
  padding: 0;    
  height: 100%;
  width: 100%;
  color:#FFF;
}

body a{
  color:#FFF;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.body{
  height: 100%;
  color:#000;
}

*:focus,
*:focus-visible {
    outline: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
}

h1{
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  margin: 48px;
}

nav {  
  list-style: none;
}

/*nav a {
  font-weight: bold;
  color: #2c3e50;
  margin-right: 32px;
    padding: 8px 12px;
}

nav a.router-link-exact-active {
  color: #42b983;
}*/

/* spinner */
.lds-dual-ring {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;  
  top: 45%;
  left: 45%;
  z-index: 9999999;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #7827DA;
  border-color: #7827DA transparent #7827DA transparent;
  animation: lds-dual-ring 0.7s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
