import CategoriesContainer from './CategoriesContainer.vue';
import Categories from './Categories.vue';
import CategoriessAdd from './add.vue';

const categories = {
    path: '/dictionary/categories',
    name: 'categories',
    meta:{
        h1: 'Категорії товарів',
    },
    children:[
      {
        path: '/dictionary/categories',
        name: 'categories',
        component: Categories
      },
      {
        path: 'add',
        name: 'categories_add',
        component: CategoriessAdd
      }
    ],
    component: CategoriesContainer,
}

export default categories