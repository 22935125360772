import { createRouter, createWebHistory } from 'vue-router'

//auth
import { isAuthenticated } from '../auth'
//home
import home from '../views/home/routes'
//cashbox
import cashbox from '../views/cashbox/routes'
//inventory
import inventory from '../views/inventory/routes'
//stock
import stock from '../views/stock/routes'
//dictionary
import dictionary from '../views/dictionary/routes'
//users
import users from '../views/users/routes'

const routes = [
  home,
  stock,
  dictionary,
  cashbox,
  inventory,
  users,
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(function (to, from, next) {
    if ((to.path !== '/login' && to.path !== '/login/') && !isAuthenticated()) {
        next({ path: '/login' })
    } else if ((to.path === '/login' || to.path === '/login/') && isAuthenticated()) {
        next({ path: '/' })
    } else {
        next()
    }
});

export default router