//stock
import StockView from './Stock.vue';
import StockContainer from './StockContainer.vue';

//invoice purchase
import invoice_purchase from './Invoice/purchase/routes';

//invoice outgoing
import invoice_outgoing from './Invoice/outgoing/routes';

//goods
import goods from './goods/routes';

//sales
import sales from './sales/routes';

//returns
import returns from './returns/routes';

//deliveryOrder
import deliveryOrder from './deliveryOrder/routes';

const stock =
  {
    path: '/stock',
    name: 'stock',
    meta:{
      h1: 'Огляд складу'
    },
    children:[
      {
        path: '',
        name: 'stock',
        component: StockView
      },
      invoice_purchase,
      invoice_outgoing,
      goods,
      sales,
      returns,
      deliveryOrder
    ],
    component: StockContainer
  }

  export default stock