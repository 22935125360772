<template>
    <div class="popup-overlay">
        <div class="popup-content">
            <i @click="closePopup" class="x fa fa-times"></i>
            <h3>Додати поля</h3>
            <div class="column-wrap">
                
                <!-- loading -->
                <div v-if="loading" class="lds-dual-ring"></div>
              
                <div class="column">
                    <div class="item" v-for="field in fields" :key="field.id">
                        <label :for="field.id">{{ field.name }}</label>
                        <input type="checkbox" :id="field.id" :value="field.id" v-model="checked">
                    </div>
               </div>
            </div>
            
            <button type="submit" class="purpur" style="margin:0;" @click="closePopup()">OK</button>
            <!-- {{ popupData }} -->
        </div>
    </div>
</template>
  
<style scoped>
  .popup-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content{
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }
  .column-wrap{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  } 
  .column-wrap .column{
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .column-wrap .column img{
    max-width: 250px;
    max-height: 250px;
  }
  .item{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 5px;
  }
  .item span{
    color:#667085;
  }
  .item p{
    color: #101828;
    font-size: 16px;
    font-weight: bold;
    margin: 8px 0;
  }
  .fa-files-o{
    color: #667085;
    font-size: 18px;
    cursor: pointer;
  }
  .x{
    float: right;
    cursor: pointer;
  }  
</style>

<script>
    export default {
        props: [          
          'popupData'          
        ],
        data() {
            return {
                loading: false,
                fields: [],
                outFields: [],
                checked: [],
            };
        },
        methods: {
            closePopup() {
                this.ReloadPopupelEments();
                this.$emit('closePopup');                
            },
            ReloadPopupelEments() {
                this.loadData();
                
                this.outFields = [];
                this.checked.forEach(el => {
                    if( el !== -1 ){ this.outFields.push(this.fields[el-1]); }
                });
                this.outFields.sort((a, b) => a.id - b.id);
                this.$emit('popupData', this.outFields);
            },
            loadData(){
              this.loading = true;                
              this.$axios.get('/anyfieldsitems')
                  .then(response => {
                      this.checked = [];
                      this.fields = response.data.items;

                      if( this.popupData.length > 0 ){
                          this.popupData.forEach(element => {
                              this.checked.push(element.id);
                          });
                      }

                      this.loading = false;                    
                  })
                  .catch(error => {
                      console.error(error);
                  });
              },            
        },
        created() {

        },
        mounted(){            
            this.loadData();
        },
    };
</script>