<template>
    <form class="table-wrapper">
        <table>
            <thead>
                <tr>
                    <td style="width:3%;"><input type="checkbox"></td>
                    <td>№</td>
                    <td>Товар</td>
                    <td>Поставщик</td>
                    <td>Вхідна ціна</td>
                    <td>Поточна ціна</td>
                    <td>В наявності</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in TablesItems" :key="item.id">
                    <td><input type="checkbox"></td>
                    <td>{{ item.number }}</td>
                    <td>{{ item.item }}</td>
                    <td>{{ item.shipper.name }}<p>{{ item.shipper.email }}</p></td>
                    <td>{{ item.shipper_price }}</td>
                    <td>{{ item.price }}</td>
                    <td>{{ item.stock }}</td>
                </tr>
            </tbody>            
        </table>
    </form>

</template>

<style>
.table-wrapper{
    padding: 0 40px;
}
thead tr td{
    color:#667085;
}

table {
    width: 100%;
    border-collapse: collapse;  
}
th, td {
    vertical-align: middle;
    text-align: left;
    padding: 7px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}
th {
    font-weight: bold;
}
td p {
    margin: 0;
    color:#667085;
}
tr th:first-child,
tr td:first-child {    
    text-align: left;
    max-height: 52px;    
}
td:nth-child(3) {
    color:#667085;    
}
tbody tr:nth-child(even) {
  background-color:#F9FAFB;
}
tbody tr:nth-child(odd) {
  background-color: #fff;
  border-top: 2px solid #EAECF0;
  border-bottom: 2px solid #EAECF0;
}
input[type=checkbox]{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.tbl-buttons{
    text-align: center;
    font-size: 22px;
    color: #667085;
    cursor: pointer;
}
.tbl-buttons.right{
    text-align: right;
}
</style>

<script>    
    export default{ 
        name:'GoodsList',
        data() {            
            return {
                
            }
        },
        props:{
            TablesItems:Object
        },
        methods:{
            // open(i){                
            //     this.$router.push({
            //         name: this.TablesItems[0].type + "_invoice_id",
            //         params: {
            //             invoice_id: i,
            //         },
            //     });                
            // },
            // edit(i){                
            //     this.$router.replace({ path: this.$route.href + "/" + i + "/edit" });
            // },
        },
        components: {
            
        }
    }
</script>