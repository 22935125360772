import CustomersContainer from './CustomersContainer.vue';
import Customers from './Customers.vue';
import CustomersAdd from './add.vue';

const customers = {
    path: '/dictionary/customers',
    name: 'customers',
    meta:{
        h1: 'customers',
    },
    children:[
      {
        path: '/dictionary/customers',
        name: 'customers',
        component: Customers
      },
      {
        path: 'add',
        name: 'customers_add',
        component: CustomersAdd
      }
    ],
    component: CustomersContainer,
}

export default customers